/* Site Utilities module for truview site
 * Copyright Leica Geosystems (c) 2015 All rights reserved
 * ===============================================================================
 */


// export namespace truview {

import $ from 'jquery';
import * as Q from 'q';
import * as _ from 'lodash';
import moment from 'moment';
import tc from 'tinycolor2';

declare function require(name:string);
require('moment/locale/de'); // 'German',
require('moment/locale/ja'); // 'Japanese',
require('moment/locale/it'); // 'Italian',
require('moment/locale/ru'); // 'Russian',
require('moment/locale/nl'); // 'Dutch',
require('moment/locale/pl'); // 'Polish',
require('moment/locale/es'); // 'Spanish',
require('moment/locale/zh-cn'); // 'Chinese (China)',
require('moment/locale/zh-tw'); // 'Chinese (Taiwan)',
require('moment/locale/hu'); // 'Hungarian'
require('moment/locale/fr'); // 'French
require('moment/locale/fi'); // 'Finnish
require('moment/locale/da'); // 'Danish
moment.locale('en');

import * as tvBase from '../tvBase';
import {IconName} from '../common.ui/icon';


// ==================================================================================
/*** Temporary disabled css broserify bundle ( @ifdef PRODUCTION not working )
require('../../site.css');
@endif
***/


export interface LocaleDictionary {
  [localeid: string]: any;
}

export interface LocaleCodes {
  [localid: string]: string;
}

export interface OpenParameters {
  [ name: string] : string;
}

export interface License {
  type?: 'cloud' | 'enterprise';
  build: string;
  server: string;
  port?: number;
  generator: boolean;
  users: number;    // Number of max allowed users per portal
  portals: number;  // Number of max allowed portals
  valid: boolean;
  expires: number;
  apps?: boolean;
  appLicense?: any;
  nodeVersion: string;
}

export interface TVGServiceFeature {
  activationId: string;
  expire: Date;
  description: string;
  scanPositions: number;    // Max number of scan position allowed
  filestoreSize: number;      // Max size of file storage allowed for each scan position in MB
  api?: boolean;            // Set if the API are accessible or not.
  backup?: boolean;         // Set if customer has paid for backup/restore functionality
}
/**
 * Basic Service License. An instance of this is sent to the service during activation
 */
export interface ServiceLicense {
  type?: 'cloud' | 'enterprise';
  eid: string;           // The EID that created this license.
  service: string;    // This is the name of the service this license is targeting.
  expire: Date;       // Official Expiration date
  demo: boolean;      // A demo license may cause the service to completely shutdown without data backup.
  cors: boolean;
  apps: boolean;
  nodeVersion: string;
  features: TVGServiceFeature[];      // Specific features enabled by this license.
}

export interface VersionInfo {
  version: string;
  branch: string;
  date: number;
}

export interface ReqResponse {
  success: boolean;
  message: string;
}

export interface LicenseResponse {
  success: boolean;
  portalid: string;
  licenseid: string;
  user: UserInfo;
}

export interface PortalInfo {
  users: number;
  sites: number;
  scans: number;
}

export interface PortalResponse {
  success: boolean;
  id: string;
  user: UserInfo;
  info?: PortalInfo;
}

export interface AppResponse {
  success: boolean;
  id: string;
  user: UserInfo;
  info?: Application;
}


export interface BackupInfo {
  _id?: string;
  startTime: string;
  endTime?: string;
  size?: number;
  zip?: string;
  locations?: number;
  scans?: number;
  users?: number;
  bkType?: string;
  bkScope?: string;
  portalsRef?: tvBase.PortalRef[];
}

export interface BackupResults {
  backups: BackupInfo[];
  total: number;
  start: number;
  count: number;
}




export interface UserInfo {
  id: string;
  username: string;
  email: string;
  firstname?: string;
  lastname?: string;
  role: string;
  status: string;  // it can be 'active', 'inactive', 'pending', or 'reset'
  language: string;
  portal?: tvBase.PortalRef;
  portals?: tvBase.PortalRef[];
  password?: string;
  confpassword?: string;
  resetuser?: boolean;
  source?: string;
  linked?: boolean;
  company?: string;
  country?: string;
  over16?: boolean; // GDPR stuff
  allowShare?: boolean; // GDPR stuff
  allowCookie?: boolean; // GDPR stuff
  allowProcessing?: boolean; // GDPR stuff
}

export interface Users {
  users?: UserInfo[];
  start?: number;
  count?: number;
  total?: number;
  totals?: UsersTotals;
  filter?: string;
  query?: string;
  filtername?: string;
  portalid?: string;
}

export interface UsersTotals {
  active: number;
  inactive: number;
  pending: number;
  reset: number;
  total: number;
}

export interface CustomSettings {
  protocol?: string;
  hostname?: string;
  port?: number;
  homepage?: string;
  ownerinfo?: {
    name: string;
    contactEmail?: string;
    phone?: string;
    address?: string;
    stateregion?: string;
    country?: string;
    zipcode?: string;
  };
  customlogo?: string;
  allowRequestAccess?: boolean;
  showlicense?: boolean;
}

export interface SmtpAuthOptions {
  user?: string;
  pass?: string;
  xoauth2?: any;
}

export interface SmtpSettings {
  service?: string;
  port?: number;
  host?: string;
  secure?: boolean;
  authMethod?: string;
  auth?: SmtpAuthOptions;
  name?: string; // optional hostname of the client, used for identifying to the server
  fromAddr?: string;
}

/**
* Definition of a generic alert message
*/
export interface AlertMessage {
  subject: string;
  content: string;
}

export interface TaskRequest {
  id: string;
  reqType: string;
  inprogress: boolean;
  queued: string;
  user: UserInfo ;
}

export interface LogEntries {
  entries: any[];
}

export interface ActionQueue {
  entries: any[];
}


/**
* Event type definition, describes a type of event that may be generated by the system
*/
export interface EventType {
  name: string;
  enabled: boolean;
  message?: AlertMessage;
  recipients?: string[];
}

/**
* Available Events
*/
export interface EventTypes {
  [name: string]: EventType;
}

export interface Application {
  id?: string;
  appid: string;
  description?: string;
  callbackurl?: string;
  secret?: string;
  roles : {
    user: boolean;
    poweruser: boolean;
    admin: boolean;
  };
  serviceapp?: boolean;
}

export interface PortalLicense {
  id: string;
  users: number;
  scans: number;
  expires: string;
}

export interface Portal {
  id: string;
  name: string;
  description: string;
  isDefault?: boolean;
  allowUpload?: boolean;
  allowPublish?: boolean;
  activation?: PortalLicense;
}

// export interface PortalRef {
//   id: string;
//   name: string;
//   active?: boolean;
// }

export interface ScansPage {
  scans: tvBase.ScanInfo[];
  start: number;
  count: number;
  sortby?: string;
  sortdir?: string;
  query?: string;
  complete: boolean;
  total: number;
  siteid?: string;
  ispublic?: boolean; // Used when we return the Scans from a single location - indicates if the location is public or not.
}

export interface MapSize {
  w: number;
  h: number;
}

export interface ScanPosition {
  name: string;
  id: string;
  coords: { x: number; y: number; r: number };
}

export interface MapImage {
  name: string;
  w: number;
  h: number;
  isOverviewImage: boolean;
}

export interface ScanArea {
  triangles: number[];
  href: string;
}

export interface SiteMap {
  areas: ScanArea[];
  scans: ScanPosition[];
  image: MapImage;
  name: string;
}

export interface SiteMapInfo {
  siteid: string;
  name?: string;
  // size: MapSize;                   // Obsolete for single image sitemap!
  // image: string;                   // Obsolete for single image sitemap!
  // scanPositions?: ScanPosition[];  // Obsolete for single image sitemap!
  maps: SiteMap[];
}

export interface ScanResult {
  scans: tvBase.ScanInfo[];
  start: number;
  count: number;
  sortby?: string;
  sortdir?: string;
  complete: boolean;
  total: number;
  siteid?: string;
  ispublic?: boolean; // Used when we return the Scans from a single location - indicates if the location is public or not.
}

export interface SiteGeotags {
  geotag: tvBase.GeoTag[];
  mappingInfo: any;
  ucs: any;
}

export interface GeoTagRef {
  distance: number;
  tag: string;
}

export interface SiteScanInfo {
  geotags?: GeoTagRef[];
  id: string;
  name: string;
}

/** @internal */
export type MeasureUnit = 'm' | 'cm' | 'mm' |  'in' | 'ft' | 'yd' | 'ft (US Survey)' | 'in (US Survey)';

// export type Tool = 'layers' | 'snapshots' | 'measure' | 'neighbors' | 'geotags' | 'minimap'; 

export interface HideTools {
  layers?: boolean;
  snapshots?: boolean;
  measure?: boolean;
  neighbors?: boolean;
  geotags?: boolean;
  minimap?: boolean;
}

/** @internal */
export interface SiteCustomize {
  markers?: {
    color?:  Color;
    size?: number;
    max?: number,
    showLabels?: boolean;
    showMarkers?: boolean;
  };
  units?: MeasureUnit;
}

export interface SiteMeta {
  id: string;
  metadata?: MetaData;
  location?: { scanworld: SiteScanInfo[] };
}

export interface MetaData {
  id: string;
  name: string;
  deleted?: boolean;
  loaded?: boolean;
  description: string;
  geotags?: SiteGeotags;
  image?: any;
  imported: string;
  importedby: any;
  location: any;
  modified: string;
  permissions: any;
  sites: any[];
  maps: any[];
  files: tvBase.SiteFile[];
  customize: SiteCustomize;
}

export interface ServerError {
  version: string;
  result: string;
  error: {
    name: string;
    message: string;
    stack: string[];
  };
}

export interface UpdateReqResponse {
  updating: boolean;
  toVersion: string;
  message?: string;
}

export interface UserPolicy {
  allowMultiMemberships?: boolean;  // This policy set to true means users can belong to multiple portals
  requireFullMembership?: boolean;  // This policy set to true meand users must belong to multiple portals
}

export interface UserPolicies {
  [ userRole: string ] : UserPolicy;
}

export interface PortalCount {
  [ numberOfPortal : number ] : number;
}

export interface PortalCountPerRole {
  [ userRole : string ] : PortalCount ;
}

/** @internal */
export interface Color {
  red: number;
  green: number;
  blue: number;
  alpha?: number;
}

export interface HSBColor {
  val: number;
  sat: number;
  hue: number;
}

export var defUserPolicies : UserPolicies = {
  // by default Users cannot be allowed to access more than one portals.
  'user' : {
    requireFullMembership: false,
    allowMultiMemberships: false
  },
  // by default Power Users cannot be allowed to access more than one portals.
  'poweruser' : {
    requireFullMembership: false,
    allowMultiMemberships: false
  },
  // by default Admin cannot be allowed to access less than all portals.
  'admin' : {
    requireFullMembership: true,
    allowMultiMemberships: true
  }
};

// tslint:disable:quotemark

// export var re_weburl = new RegExp("^(https?:\/\/)?([\da-z\.-]+)(?::\\d{2,5})?\/?$");
export var re_idstr = new RegExp("^$|\\'|\\^|\\/|`|\\~|\"|\\\\|\\||>|<+");

export var re_label = new RegExp("^$|\\\\|\\||>|<+");

export var re_username = new RegExp("^$|\\s|\\{|\\}|\\[|\\]|\\'|!|@|#|%|\\^|\\&|\\*|\\(|\\)|-|_|\\+|\\=|,|\\.|\\?|\\/|;|:|`|\\~|\"|\\\\|\\||>|<+");

export var re_name = new RegExp("^$|\\{|\\}|\\[|\\]|\\'|!|@|#|%|\\^|\\&|\\*|\\(|\\)|-|_|\\+|\\=|,|\\.|\\?|\\/|;|:|`|\\~|\"|\\\\|\\||>|<+");

export var re_desc = new RegExp("^$|\\'|\\^|\\(|\\)|\\/|`|\\~|\"|\\\\|\\||>|<+");

export var re_pwd = new RegExp("^$|\\s|\\{|\\}|\\[|\\]|\\'|,|\\.|\\/|;|:|`|\"|\\\\|\\||>|<+");

export const re_invalid_filename_char = /[<>:"\/\\|?*\x00-\x1F]/g;

export var re_email = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,30}(?:\.[a-z]{2})?)$/i;
/**
  * Regular expression to validate a correct URL
  * by dperini https://gist.github.com/dperini/729294
too strict for us since it refuses http://localhost:9000
  */
export var re_weburl = new RegExp( "^" +
  // protocol identifier
  "(?:(?:https?|ftp)://)" +
  // user:pass authentication
  "(?:\\S+(?::\\S*)?@)?" +
  "(?:" +
    // IP address exclusion
    // private & local networks
    "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
    "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
    "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broacast addresses
    // (first & last IP address of each class)
    "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
    "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
    "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
  "|" +
    // host name
    "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
    // domain name
    "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
    // TLD identifier
    "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))*" +
    // TLD may end with dot
    "\\.?" +
  ")" +
  // port number
  "(?::\\d{2,5})?" +
  // resource path
  "(?:[/?#]\\S*)?" +
  "$", "i"
);

/**
  * Regular expression to validate a correct URL
  *  uses two different regexes that catches most of our cases
  *  to use: re_uri.test( 'some url' )
  */
export const re_uri = {
  test: ( str ) => {
    return re_uri_testA( str ) && re_uri_testB( str );
  }
};

function re_uri_testA( str ) {
  //  from https://github.com/formvalidation/formvalidation/blob/v0.4.0/dist/js/bootstrapValidator.js
  // Hitesh commented out the 'TLD identifier' part
  const url_test = new RegExp(
    "^" +
    // protocol identifier
    '(?:(?:https?|ftp)://)' +
    // user:pass authentication
    "(?:\\S+(?::\\S*)?@)?" +
    "(?:" +
    // IP address exclusion
    // private & local networks
    "(?!10(?:\\.\\d{1,3}){3})" +
    '(?!127(?:\\.\\d{1,3}){3})' +
    "(?!169\\.254(?:\\.\\d{1,3}){2})" +
    "(?!192\\.168(?:\\.\\d{1,3}){2})" +
    "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broacast addresses
    // (first & last IP address of each class)
    "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
    "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
    "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
    "|" +
    // host name
    "(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)" +
    // domain name
    "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*" +
    // TLD identifier  // Hitesh commented out
    // "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
    ")" +
    // port number
    "(?::\\d{2,5})?" +
    // resource path
    "(?:/[^\\s]*)?" +
    "$", "i"
  );
  return url_test.test( str );
}

function re_uri_testB( str ) {
  // https://github.com/jzaefferer/jquery-validation/blob/master/src/additional/url2.js
  return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)*(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test( str );
}
// tslint:enable:quotemark

export type IIconNameMap = {
  [key in tvBase.DatasetTypes]: IconName;
};

export const ICON_NAME_MAP: IIconNameMap = {
  cube: 'rgb',
  displayir:'ir',
  hdr: 'hdr',
  scanintensity: 'intensity_bw',
  intensityhue: 'intensity_color',
};

export const INACTIVE_ICON_COLOR = '#fff';
export const ACTIVE_ICON_COLOR = '#ff0033';

let _serveraddr: string = '';
let _accessToken: string ;

export function setAccessToken( token: string ) {
  _accessToken = token;
}

/**
 * Generic DELETE request
 */
function deleteRequest<T>( url: string, data?: any ): Q.Promise< T > {
  const later = Q.defer<T>();
  const verb = `DELETE`;
  let xOpt: JQueryAjaxSettings = {
    url: _serveraddr + url,
    cache: false,
    type: verb,
    headers: { Accept: 'application/json; charset=utf-8' }
  };
  if ( data ) {
    xOpt.data = JSON.stringify( data );
    xOpt.contentType = 'application/json; charset=utf-8';
  }
  if ( _accessToken ) {
    xOpt.beforeSend = ( xhr: JQueryXHR, settings) => xhr.setRequestHeader('Authorization','Bearer ' + _accessToken);
  }
  $.ajax(xOpt)
  .done( (data) => {
    later.resolve(data as T);
  })
  .fail( ( jqXHR: JQueryXHR /*, textStatus, errorThrown */) => {
    var msg = 'Remote DELETE request failed.';
    if ( jqXHR.responseJSON ) {
      const rxError = jqXHR.responseJSON.error;
      msg = `${rxError.name}. ${rxError.message}. DELETE (${url}). ${jqXHR.status}: ${jqXHR.statusText}`;
    }
    if (process.env.NODE_ENV === 'development') {
      console.error(msg);
    }
    later.reject(new Error(msg));
  });
  return later.promise;
}

/**
 * Generic GET request
 */
export function getRequest<T>( url: string, mime: string = null, processData: boolean = true  ): Q.Promise< T > {
  const later = Q.defer<T>();
  const verb = `GET`;
  const xOpt: JQueryAjaxSettings = {
    url: _serveraddr + url,
    cache: false,
    type: verb,
    headers: { Accept: mime || 'application/json; charset=utf-8' },
  };
  if ( _accessToken ) {
    xOpt.beforeSend = ( xhr: JQueryXHR, settings) => xhr.setRequestHeader('Authorization','Bearer ' + _accessToken);
  }
  if ( !processData ) {
    xOpt.processData = false;
  }

  $.ajax(xOpt)
  .done( (data) => later.resolve(data as T) )
  .fail( ( jqXHR: JQueryXHR /*, textStatus, errorThrown */) => {
    let msg = 'Remote GET request failed.';
    if ( jqXHR.responseJSON ) {
      const rxError = jqXHR.responseJSON.error;
      msg = `${rxError.name}. ${rxError.message}. GET (${url}). ${jqXHR.status}: ${jqXHR.statusText}`;
    }
    if (process.env.NODE_ENV === 'development') {
      console.error(msg);
    }
    const err = new Error(msg);
    err['jqXHR'] = jqXHR;
    later.reject(err);
  });
  return later.promise;
}

/**
 * Generic POST request
 *
 * @template T
 * @param {string} url (description)
 * @param {(T | any)} [data={}] (description)
 * @param {*} [mXOpt={}] more additional ajax options
 * @returns {Q.Promise< T >}
 */
function postRequest<T>( url: string, data: T | any = {}, mXOpt: any = {} ): Q.Promise< T > {
  var later = Q.defer<T>();
  const verb = `POST`;
  let xOpt: JQueryAjaxSettings = {
    url: _serveraddr + url,
    cache: false,
    type: verb,
    contentType: 'application/json; charset=utf-8',
    headers: { Accept: 'application/json; charset=utf-8' },
    data: JSON.stringify( data )
  };
  if ( _accessToken ) {
    xOpt.beforeSend = ( xhr: JQueryXHR, settings) => xhr.setRequestHeader('Authorization','Bearer ' + _accessToken);
  }
  xOpt = _.merge(xOpt, mXOpt);
  $.ajax(xOpt)
  .done( (data) => {
    later.resolve(data as T);
  })
  .fail( ( jqXHR: JQueryXHR /*, textStatus, errorThrown */) => {
    var msg = 'Remote POST call failed.';
    if ( jqXHR.responseJSON ) {
      msg = `POST (${url}). ${jqXHR.status}: ${jqXHR.statusText}`;
      const rxError = jqXHR.responseJSON.error;
      if (rxError) {
        msg = `${rxError.name}. ${rxError.message}. ${msg}`;
      }
    }
    if (process.env.NODE_ENV === 'development') {
      console.error(msg);
    }
    const err = new Error(msg);
    err['jqXHR'] = jqXHR;
    later.reject(err);
  });
  return later.promise;
}

import { Store } from '../lib/Store';
import { handleLoginWithReturnUrl } from '../lib/session';

async function handleRequestError2(err: Error) {
  const jqXHR = err['jqXHR'] as any;
  const store = window['store'] as Store;
  if (jqXHR && jqXHR.status === 401) {
    // graceful login for unauthorized case
    return handleLoginWithReturnUrl(null);
  } else if (store && jqXHR && jqXHR.responseJSON && jqXHR.responseJSON.error && jqXHR.responseJSON.error.message) {
    store.flash.errorNext(jqXHR.responseJSON.error.message);
    window.location.href = '/';
    return await new Promise(() => null);
  }
  console.log('handleRequestError2', err);
}

export async function postRequest2(url: string, data: any = {}): Promise<any> {
  try {
    return await postRequest<any>(url, data);
  } catch (err) {
    await handleRequestError2(err);
  }
}

export async function getRequest2(url: string) {
  try {
    return await getRequest<any>(url);
  } catch (err) {
    await handleRequestError2(err);
  }
}

export async function patchRequest2(url: string, data: any = {}): Promise<any> {
  try {
    return await patchRequest<any>(url, data);
  } catch (err) {
    await handleRequestError2(err);
  }
}

/**
 * Generic POST request
 *
 * @template T
 * @param {string} url (description)
 * @param {(T | any)} data (description)
 * @param {*} [mXOpt={}] optional, additional ajax options
 * @returns {Q.Promise< T >}
 */
function patchRequest<T>( url: string, data: T | any, mXOpt: any = {}  ): Q.Promise< T > {
  var later = Q.defer<T>();
  const verb = `PATCH`;
  let xOpt: JQueryAjaxSettings = {
    url: _serveraddr + url,
    cache: false,
    type: verb,
    contentType: 'application/json; charset=utf-8',
    headers: { Accept: 'application/json; charset=utf-8' },
    data: JSON.stringify( data )
  };
  if ( _accessToken ) {
    xOpt.beforeSend = ( xhr: JQueryXHR, settings) => xhr.setRequestHeader('Authorization','Bearer ' + _accessToken);
  }
  xOpt = _.merge(xOpt, mXOpt);

  $.ajax(xOpt)
  .done( (data) => {
    later.resolve(data as T);
  })
  .fail( ( jqXHR: JQueryXHR , textStatus, errorThrown ) => {
    let msg = 'Remote PATCH request failed.';
    if ( jqXHR.responseJSON ) {
      const rxError = jqXHR.responseJSON.error;
      msg = `${rxError.name}. ${rxError.message}. PATCH (${url}). ${jqXHR.status}: ${jqXHR.statusText}`;
    }
    if (process.env.NODE_ENV === 'development') {
      console.error(msg);
    }
    later.reject(new Error(msg));
  });
  return later.promise;
}


/**
 * Merge a list of portals into the portal list of a given user
 */
export function mergePortals( orgPortals: tvBase.PortalRef[], newPortals: tvBase.PortalRef[] ): tvBase.PortalRef[] {
  let mergedPortals = _.map( orgPortals, pr => { return { id: pr.id, name: pr.name, active: true } as tvBase.PortalRef; } );
  _.each( newPortals, (ap) => {
    if ( !_.find( mergedPortals, (ep: tvBase.PortalRef) => ep.id === ap.id ) ) {
      const mp = _.clone(ap);
      delete mp.active;
      mergedPortals.push(mp);
    }
  });
  return _.sortBy(mergedPortals, (p) => p.id );
}

/**
  * Retrieve the URL Parameters
  */
export function getUrlVars() : OpenParameters {
  let vars: OpenParameters = {};
  var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for(var i = 0; i < hashes.length; i++) {
    const hash = hashes[i].split('=');
    vars[hash[0]] = hash[1];
  }
  return vars;
}

/**
  * Compute the site URL string from the custom settings.
  */
export function assembleSiteUrl( settings: CustomSettings ): string {
  if (!settings)
    return '';
  var siteUrl = '';
  if (settings.protocol)
    siteUrl += settings.protocol + '//';
  siteUrl += settings.hostname || '';
  if (settings.port)
    siteUrl += ':' + settings.port;
  return siteUrl;
}

export function escapeContent( content : string ) : string {
  var div = document.createElement('div');
  div.appendChild(document.createTextNode(content));
  return div.innerHTML;
}

/**
 * Unescape the given string
 *
 * @export
 * @param {string} content string to unescape
 * @returns {string} unescaped result
 */
export function unescapeContent( content : string ) : string {
  var e = document.createElement('div');
  e.innerHTML = content;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

// ============================= REST CALLS ====================================

/**
 * Save server custom settings
 * admin role required
 */
export function saveCustomSettings( st: CustomSettings ) : Q.Promise<CustomSettings> {
  var later = Q.defer<CustomSettings>();
  _.each( _.keys( st.ownerinfo) , ( okey ) => {
    st.ownerinfo[okey] = escapeContent(st.ownerinfo[okey]);
  });
  postRequest<any>('/admin/customize',{ customsettings: st })
  .then( (data) => {
    later.resolve(st);
  })
  .fail( ( err: Error ) => later.reject(err) );
  return later.promise;
}

/**
 * Save user events definition.
 * admin role required
 *
 * @export
 * @param {string[]} events is an array of event names to be saved as active user events
 * @returns {Q.Promise<void>} (description)
 */
export function saveUsrEvents(events: string[]) : Q.Promise<void> {
  return postRequest<void>('/admin/events?type=user', events );
}

/**
 * Save system events definition.
 * admin role required
 *
 * @export
 * @param {string[]} events is an array of event names to be saved as active system events
 * @returns {Q.Promise<void>}
 */
export function saveSysEvents(events: string[]) : Q.Promise<void> {
  return postRequest<void>('/admin/events?type=sys', events );
}

/**
 * Save the given SMTP setting to the server.
 * admin role required.
 * This action may trigger an attempt to send a email alert to the user
 * performing the save.
 *
 * @export
 * @param {*} newSettings as javascript object
 * @returns {Q.Promise<void>}
 */
export function saveSmtpSettings( newSettings : any ) : Q.Promise<void>  {
  return postRequest<void>('/admin/smtp', newSettings );
}

/**
 * Ask the server to Acquire a license from the set license server
 *
 * @export
 * @param {string} serverName name of the server to use to acquire the license
 * @returns {Q.Promise<License>} a promise with the acquired license if succeded
 */
export function acquireLicense( serverName : string ) : Q.Promise<License> {
  return postRequest<License>('/admin/license', { license: { server: serverName } });
}

/**
 * Retrieve ths info for the latest version of TVG available
 * admin role required
 */
export function getUpdate( checkNow: boolean = false ) : Q.Promise<any> {
  return getRequest<any>(`/admin/update/?check=${checkNow ? 'now' :'none'}`);
}

/**
 * Delete a user with the given ID
 * admin role required
 */
export function deleteUser( id: string  ) : Q.Promise<void> {
  return deleteRequest<void>(`/admin/user/${id}`);
}

/**
 * Deletes user completely and anonymises all data
 * Function is called by user themselves.
 * 
 * @export
 * @param {string} id 
 * @returns {Q.Promise<void>} 
 */
export function delAndAnonymiseUser( id: string ) : Q.Promise<void> {
  return deleteRequest<void>( `/privacy/${id}`);
}

/**
 * Retrieve all the active sessions on the server
 * admin role required.
 */
export function getSessions( portal: string = '' ): Q.Promise< any[] > {
  return getRequest< any[] >(`/admin/sessions?portal=${portal}`);
}

/**
 * Retrieve the users registered in the server within a given portal by role.
 * admin role is required.
 *
 * @export
 * @param {string} [portal='all'] portal The id of the portal
 * @param {string} [roleFilter='user'] roleFilter The name of the role
 * @param {string} query a string to search for: it matches name lastname and username
 * @param {number} start (description)
 * @param {string} count (description)
 */
export function getUsersByRole( portal: string = 'all',
    roleFilter: string = 'user',
    query: string = '',
    start: number = 0,
    count: number = -1 ): Q.Promise< Users >  {
  var later = Q.defer<Users>();
  let url = `/users/${portal}?role=${roleFilter}`;
  if ( query !== '' ) {
    url += `&q=${query}`;
  }
  if ( count > 0 ) {
    url += `&start=${start}&count=${count}`;
  }
  getRequest<any>(url)
  .then( ( data ) => {
    if (data.result) {
      later.resolve({
        users: data.result.users,
        start : data.result.start,
        count : data.result.count,
        total : data.result.total,
        totals : data.totals,
        filter: data.filter,
        query: query,
        filtername: data.filtername,
        portalid: data.portalid,
      });
    }
    else {
     later.resolve({ users: [] });
    }
  })
  .fail( ( err:Error ) => later.reject(err) );
  return later.promise;
}

/**
 * Retrieve the users registered in the server within a given portal by status.
 * admin role is required.
 *
 * @export
 * @param {string} [portal='all'] the id of the portal
 * @param {string} [statusFilter='active'] a string with the status to query: 'active', 'inactive', 'pending', 'reset'
 * @param {string} [query=''] a string to search for: it matches name lastname and username
 * @param {number} [start=0] (description)
 * @param {number} [count=10] (description)
 * @returns {Q.Promise< Users >} (description)
 */
export function getUsersByStatus( portal: string = 'all',
                                  statusFilter: string = 'active',
                                  query: string = '',
                                  start: number = 0, count: number = 10 ): Q.Promise< Users > {
  var later = Q.defer<Users>();
  let url = `/users/${portal}?status=${statusFilter}`;
  if ( query !== '' ) {
    url += `&q=${query}`;
  }
  if ( count > 0 ) {
    url += `&start=${start}&count=${count}`;
  }
  getRequest<any>(url)
  .then( ( data ) => {
    if (data.result) {
      later.resolve({
        users: data.result.users,
        start : data.result.start,
        count : data.result.count,
        total : data.result.total,
        totals : data.totals,
        filter: data.filter,
        query: query,
        filtername: data.filtername,
        portalid: data.portalid
      });
    }
    else {
      later.resolve({ users: [] });
    }
  })
  .fail( ( err:Error ) => later.reject(err) );
  return later.promise;
}

export function getAllUsers( query: string, start: number, count: number ): Q.Promise< Users > {
  const later = Q.defer< Users >();
  let url = `/users/all?`;
  if ( count > 0 ) {
    url += `start=${start}&count=${count}&`;
  }
  if ( query !== '' ) {
    url += `q=${query}`;
  }
  getRequest<any>(url)
  .then( (data) => {
    if (data.result) {
      later.resolve({
        users: data.result.users,
        start : data.result.start,
        count : data.result.count,
        total : data.result.total,
        totals : data.totals,
        filter: data.filter,
        query: query,
        filtername: data.filtername,
        portalid: data.portalid
      });
    }
    else {
      later.resolve({ users: [] });
    }
  })
  .fail( ( err:Error ) => later.reject(err) );
  return later.promise;
}

export function getServerLog( since: string ) : Q.Promise<LogEntries> {
  let later = Q.defer<LogEntries>();
  getRequest<any>(`/admin/logs?upto=${since}`)
  .then( ( data: any ) => later.resolve(data.log) )
  .fail( ( err: Error) => later.reject(err) );
  return later.promise;
}

export function getActionLog( since: string ) :  Q.Promise<LogEntries>  {
  let later = Q.defer<LogEntries>();
  getRequest<any>(`/dataset/import/log?upto=${since}`)
  .then( ( data: any ) => later.resolve(data.log) )
  .fail( ( err: Error) => later.reject(err) );
  return later.promise;
}

export function getActionQueue() : Q.Promise<TaskRequest[]> {
  let later = Q.defer<TaskRequest[]>();
  getRequest<any>(`/dataset/import/queue`)
  .then( ( data: any ) => later.resolve(data.queue) )
  .fail( ( err: Error) => later.reject(err) );
  return later.promise;
}

/**
 * Kill the currently active action in the action queue
 */
export function killActiveAction() : Q.Promise<void> {
  return deleteRequest<void>('/dataset/import/queue');
}

/**
  * Retrieves the available sites in the current active portal of the user making the request.
  */
export function getSites(): Q.Promise<tvBase.PortalSites> {
  var later = Q.defer<tvBase.PortalSites>();
  getRequest<any>('/locations')
  .then((data) => {
    later.resolve({
      sites: data.locations,
      allowPublish: data.allowPublish,
      allowUpload: data.allowUpload
    });
  })
  .fail( ( err: Error ) => later.reject(err) );
  return later.promise;
}
/**
 * Get a list of deleted sites
 * 
 * @export
 * @returns {Q.Promise<tvBase.PortalSites>} 
 */
export function getDelSites(): Q.Promise<tvBase.PortalSites> {
  var later = Q.defer<tvBase.PortalSites>();
  getRequest<any>( '/locations/deleted' )
  .then((data) => {
    later.resolve({
      sites: data.locations,
      allowPublish: data.allowPublish,
      allowUpload: data.allowUpload
    });
  })
  .fail( ( err: Error ) => later.reject( err ) );
  return later.promise;
}

/**
 * Restore an automatically backed up site on TVC
 * 
 *        TVC Only 
 * 
 * @export
 * @param {string} siteid 
 * @returns {Q.Promise<{ result: string }>} 
 */
export function restoreDelTVCSites( siteid: string ) : Q.Promise<{ result: string; }>  {
  var url = `/site/deleted/${siteid}`;
  return postRequest<{ result: string; }>( url );
}

/**
  * Request to publish (make it public) a given site.
  * @param siteid
  * @param desc
  */
export function publishSite( siteid: string, desc: string = null ) : Q.Promise<string> {
  var later = Q.defer<string>();
  var pdata: any = { permissions: { read: 'all' } };
  if (desc)
    pdata.description = escapeContent(desc);
  patchRequest<any>(`/site/permissions/${siteid}`, pdata)
    .then( (data) => {
      if (data.permissions.read !== 'all')
        later.reject(new Error('Update failed. The site could not be published'));
      else
        later.resolve(siteid);
    })
    .fail( ( err: Error ) => later.reject(err) );
  return later.promise;
}

/**
  * Request to unpublish a given site
  * @param siteid
  */
export function unpublishSite( siteid: string ) : Q.Promise<string> {
  var later = Q.defer<string>();
  var pData = { permissions: { read: 'user' } };
  patchRequest<any>(`/site/permissions/${siteid}`,pData)
  .then( (data) => {
    if (data.permissions.read !== 'user')
      later.reject(new Error('Update failed. The site could not be published'));
    else
      later.resolve(siteid);
  })
  .fail( ( err: Error ) => later.reject(err) );
  return later.promise;

}



// ================= Portals API calls ============================================================

/**
 * Enable the Portals feature on the server
 */
export function enablePortals( defaultPortalName: string ) : Q.Promise<string> {
  var later = Q.defer<string>();
  var url = `/admin/portals?enable=true&name=${encodeURI(defaultPortalName)}`;
  postRequest<any>(url)
  .then( (data) => {
    later.resolve(data.activePortal);
  })
  .fail( (err: Error ) => later.reject(err) );

  return later.promise;
}

/**
 * Return all portals defined in the server
 */
export function getAllPortals(): Q.Promise< Portal[] > {
  var later = Q.defer< Portal[] >();
  var url = '/admin/portals';
  getRequest<any>(url)
  .then( (data: any ) => {
    later.resolve(data.portals);
  })
  .fail( ( err: Error) => later.reject(err) );

  return later.promise;
}

export interface PortalSaveInfo {
  id: string;
  user: UserInfo;
}

/**
 * Retrieve a portal with the given ID
 */
export function getPortal( id: string ) : Q.Promise<Portal> {
  return getRequest<Portal>(`/admin/portal/${id}`);
}


/**
 * Save the site info
 */
export function saveSiteInfo( loc: tvBase.SiteInfo ) : Q.Promise<void> {
  return patchRequest<void>(`/site/${loc.id}`, loc );
}

/**
 * delete the site with the given ID
 */
export function deleteSite( siteid: string ) : Q.Promise<void> {
  return deleteRequest<void>(`/site/${siteid}`);
}


/**
 * edit the site name and description
 */
export function editSite( siteid: string, name: string, desc: string ) : Q.Promise<void> {
  return patchRequest<void>(`/site/${siteid}`, { name: name, description: desc } );
}


/**
 * Reset the given user password. This takes the user record to a reset state the admin must
 * change this user password to enable the user again.
 */
export function resetUserPwd( username: string, useremail: string ) : Q.Promise< UserInfo > {
  return deleteRequest<UserInfo>(`/admin/user/password/${username}?email=${encodeURIComponent(useremail)}`);
}

/**
 * Save a password for the given user (must be an admin to do this)
 */
export function saveUserPwd( id: string, pwd: string ) : Q.Promise< UserInfo > {
  return patchRequest<UserInfo>(`/admin/user/password/${id}`, { password: pwd } );
}

/**
 * Used to create a new user (if password is left black the user is created as inactive and
 * it will require an admin to set a password.
 */
export function saveNewUser( newUserInfo: UserInfo, pwd: string = '' ) : Q.Promise< UserInfo > {
  const newUser = _.merge(newUserInfo, { password: pwd });
  return postRequest<UserInfo>(`/admin/user`, newUser );
}

/**
 *
 */
export function saveUserInfo(id: string, updateUserInfo: UserInfo, pwd: string = '' ) : Q.Promise< UserInfo > {
  var newUser: any = updateUserInfo;
  if ( pwd )
    newUser = _.merge(newUser, { password: pwd });
    return patchRequest<UserInfo>(`/admin/user/${id}`, newUser );
}

/**
 * Save or update portal info.
 * The portal is saved as a new entity if the id is undefined.
 */
export function savePortal( pinfo: Portal ) : Q.Promise< PortalResponse > {
  if ( pinfo.id )
    return patchRequest<PortalResponse>(`/admin/portal/${pinfo.id}`,pinfo);
  else
    return postRequest<PortalResponse>('/admin/portal',pinfo);
}

/**
 * Delete a given Portal.
 * NOTE: this operation may lock users out of the systems if the deleted Portal was the one
 * they had as active.
 */
export function deletePortal( id: string ): Q.Promise< PortalResponse > {
  return deleteRequest<PortalResponse>(`/admin/portal/${id}`);
}

export function getPortalInfo( id: string ): Q.Promise<PortalResponse > {
  return getRequest<PortalResponse>(`/admin/portal/info/${id}`);
}

export function getSitemap( id: string) : Q.Promise<SiteMapInfo> {
  return getRequest<SiteMapInfo>(`/site/map/${id}`);
}

// this function is only used by the tvgmap component of SDK
//  It returns the image in the string 'data:image/jpeg;base64,....' format
//  so it can be included in DOM element as 'url(data:....)'
export function getSitemapImage( id: string ) : Q.Promise<string> {
  const later = Q.defer<string>();
  const request = new XMLHttpRequest();

  request.open('GET', _serveraddr + '/site/map/image/' + id, true);
  request.setRequestHeader('Content-type','image/jpeg');
  if ( _accessToken ) {
    request.setRequestHeader('Authorization', 'Bearer ' + _accessToken);
  }
  request.responseType = 'blob';
  request.send();

  request.onload = function() {
    const blob = new Blob( [ request.response ], { type: 'image/jpeg' });
    const fileReader = new window['FileReader']();
    fileReader.readAsDataURL( blob );
    fileReader.onloadend = function() {
      later.resolve( fileReader.result );
    };
  };

  request.onerror = function( error ) {
    later.reject( error );
  };

  return later.promise;
}

export function getSitescans( id: string, start: number = 0, count: number = -1, query: string = '' ) : Q.Promise<tvBase.SiteScans> {
  let url = `/site/scans/${id}?start=${start}&count=${count}`;
  if ( query && query.length > 0 ) {
    url += `&q=${encodeURI(query)}`;
  }
  return getRequest<tvBase.SiteScans>(url);
}

export function getSitesnapshots(id: string, start: number = 0, count: number = -1, query: string = '' ) : Q.Promise<tvBase.View[]> {
  let url = `/site/views/${id}?start=${start}&count=${count}`;
  if ( query && query.length > 0 ) {
    url += `&q=${encodeURI(query)}`;
  }
  return getRequest<tvBase.View[]>(url);
}


export function getAllScans( start: number, count: number, query: string ) : Q.Promise<ScanResult> {
  var later = Q.defer< ScanResult >();
  let url = `/scans?start=${start}&count=${count}`;
  if ( query && query.length > 0 ) {
    url += `&q=${encodeURI(query)}`;
  }
  getRequest<any>(url)
  .then( (data: any) => {
     later.resolve(data.scansPage);
  })
  .fail( ( err: Error) => later.reject(err) );
  return later.promise;
}

export function getSitesmeta(id: string) : Q.Promise< SiteMeta > {
  return getRequest<SiteMeta>(`/site/meta/${id}`);
}

export function getSiteGeodata( siteid: string, scanid: string ) : Q.Promise<any> {
  return getRequest<any>(`/site/geodata/${siteid}/${scanid}`);
}

export function getSiteCommondata( siteid: string, scanid: string ) : Q.Promise<any> {
  return getRequest<any>(`/site/commondata/${siteid}/${scanid}`);
}

export function saveGeotag( siteid: string, scanid: string, gtinfo: tvBase.GeoTag, apikey: string ) : Q.Promise<{ geotag: tvBase.GeoTag }> {
  const url = `/site/geodata/${siteid}/${scanid}/?key=${apikey}`;
  return postRequest<{ geotag: tvBase.GeoTag }>(url, gtinfo );
}

export function deleteGeotag(siteid: string, scanid: string, name: string, apikey: string ) : Q.Promise<{ name: string; removed: number}> {
  const url = `/site/geodata/${siteid}/${scanid}/?name=${name}&key=${apikey}`;
  return deleteRequest<{ name: string; removed: number}>(url);
}

export function getScanmeta(id: string) : Q.Promise<any> {
  return getRequest<any>(`/scan/info/${id}?info=full`);
}

export function getScan(id:string) : Q.Promise< any > {
  return getRequest<any>(`/scan/${id}`);
}

export function getScanCustomizations( id:string, feature:string = 'all' ) {
  return getRequest<{ id: string; name: string; customize: SiteCustomize }>(`/site/customize/${id}?feature=${feature}`);
}

export function updateSiteCustomizations( id: string, customData: any, apikey: string, moreAjaxOptions: any = {}  ) : Q.Promise< void > {
  const url = `/site/customize/${id}?key=${apikey}`;
  return patchRequest< void >( url, customData, moreAjaxOptions );
}

export interface ViewUpdateResponse {
  scanId: string;
  viewId: string;
  view: tvBase.View;
  user: UserInfo;
}

export function saveNewScanView( scanid: string, vdata: tvBase.View, apikey: string, moreAjaxOptions: any = {}  ) : Q.Promise<ViewUpdateResponse> {
  const url = `/scan/view/${scanid}/${vdata.guid}?key=${apikey}`;
  return postRequest<ViewUpdateResponse>(url,vdata, moreAjaxOptions);
}

export function updateScanView( scanid: string, vdata: tvBase.View, apikey: string, moreAjaxOptions: any = {}  ) : Q.Promise<ViewUpdateResponse> {
  const url = `/scan/view/${scanid}/${vdata.guid}?key=${apikey}`;
  return patchRequest<ViewUpdateResponse>(url,vdata, moreAjaxOptions);
}

export function getScanPoint( scanid: string, faceid: number, u: number, v: number, apikey: string ) : Q.Promise< tvBase.ReadDataType > {
  const url = `/scan/point/${scanid}/${faceid}?u=${u}&v=${v}&key=${apikey}`;
  return getRequest<{ point: number[] }>(url);
}

export function saveUserPolicies( np: UserPolicies ) : Q.Promise<UserPolicies> {
  return postRequest<UserPolicies>('/admin/portals/policies',np);
}

export function getPortalCountPerRole() : Q.Promise<PortalCountPerRole> {
  return getRequest<PortalCountPerRole>('/admin/portals/stats');
}

export function getUserPolicies( ) : Q.Promise<UserPolicies> {
  return getRequest<UserPolicies>('/admin/portals/policies');
}

/**
 * Change a password for a given user (must be the same user and the old password must pass)
 */
export function changeUserPwd( id: string, currPwd: string , newPwd: string ): Q.Promise< UserInfo > {
  return postRequest<UserInfo>(`/admin/user/password/${id}`, { pwd: currPwd, newpwd: newPwd } );
}

/**
 * Submit a request for a backup operation
 */
export function requestNewBackup( bkscope: string, bktype: string ) : Q.Promise<number> {
  return postRequest<number>(`/admin/backup?type=${bktype}&scope=${bkscope}`, { } );
}

export function getBackups( start: number, count: number ) : Q.Promise<BackupResults>  {
  return getRequest<BackupResults>(`/admin/backups/list?start=${start}&count=${count}`);
}

export function deleteBackup( id: string ) : Q.Promise<string> {
  return deleteRequest<string>(`/admin/backup/${id}`);
}

export function setPortalLicense( pid: string, lid: string ) : Q.Promise< LicenseResponse > {
  return patchRequest<LicenseResponse>(`/admin/portal/license/${pid}?lid=${lid}`, { } );
}


/**
  * Restore data from an existing backup
  * @param siteid
  * @param filename (optional)
  * @param portal (optional) is the portal from which the restore operation is triggered.
  */
export function restoreFromBackup( siteid: string, filename?: string, portal: tvBase.PortalRef = null ) : Q.Promise<string>  {
  var url = `/admin/backup/${siteid}?type=data`;
  if ( filename ) {
    url += `&fn=${encodeURI(filename)}`;
  }
  return patchRequest<string>( url, portal || {} );
}

/**
 *
 */
export function get( url: string ) : Q.Promise<string> {
  return getRequest<string>(url, 'text/plain; charset=utf-8' );
}

export function setServer( hostname: string ) {
  _serveraddr = hostname;
}

export function getServer() {
  return _serveraddr;
}
export function getDictionary( code: string ): Q.Promise<LocaleDictionary> {
  return getRequest<LocaleDictionary>(`/dict/${code}`,'application/json; charset=utf-8');
}

/**
 * Remote call to save a new app or update an exisiting one to the server
 *
 * @export
 * @param {Application} a is the Application descriptor to save
 * @returns {Q.Promise<string>} A promise with the id of the saved app
 */
export function saveApp( a: Application ) : Q.Promise<string> {
  return postRequest<string>(`/admin/app/${a.id || 'new'}`, a );
}

/**
 * Remote call to retrieve app from the server
 *
 * @export
 * @param {Application} id is the Application DB id
 * @returns {Q.Promise<string>} A promise with the id of the saved app
 */
export function getApp( id: string ) : Q.Promise<Application> {
  return getRequest<Application>(`/admin/app/${id}`);
}

/**
 * Returns the app secret key given its id
 *
 * @export
 * @param {string} id (description)
 * @returns {Q.Promise<string>} (description)
 */
export function getAppSecret( id: string ) : Q.Promise<{key:string}> {
  return getRequest< {key:string} >(`/admin/app/secret/${id}`);
}

/**
 * Remote call to retrieve app from the server
 *
 * @export
 * @param {Application} appid is the Application db id
 * @returns {Q.Promise<string>} A promise with the id of the saved app
 */
export function getAppByAppId( id: string ) : Q.Promise<Application> {
  return getRequest<Application>(`/admin/app/appid:${id}`);
}

/**
 * Remote call to retrieve all the application registered with the server
 *
 * @export
 * @param {number} [start=0] initial item to return
 * @param {number} [count=-1] max number of item to return
 * @returns {Q.Promise<Application[]>} a promise with an array of Application items.
 */
export function getApps( start: number =0, count: number = -1 ) : Q.Promise<Application[]> {
  return getRequest<Application[]>('/admin/apps/list');
}

/**
 * Delete a given Portal.
 * NOTE: this operation may lock users out of the systems if the deleted Portal was the one
 * they had as active.
 */
export function deleteApp( id: string ): Q.Promise< AppResponse > {
  return deleteRequest<AppResponse>(`/admin/app/${id}`);
}


/**
 * OAuth2 Authorization Code Response
 * See FC 6749 at 4.1.2
 * http://tools.ietf.org/html/rfc6749#section-4.1.2
 *
 * @interface OAuthACResponse
 */
export interface OAuthACResponse {
  code: string;
  state?: string;
}

/**
 * OAuth2 Access Code Response
 * See FC 6749 at 4.1.4
 * http://tools.ietf.org/html/rfc6749#section-4.1.3
 *
 * @interface OAuthATResponse
 */
export interface OAuthATResponse {
  access_token:string;
  token_type:string;
  expires_in:number;      // in seconds
  refresh_token:string;
}

/**
 * Post a request for a "Authorization Code".
 * Note this request is authenticated and requires admin privileges in TVG.
 * See FC 6749 at 4.1.2
 * http://tools.ietf.org/html/rfc6749#section-4.1.2
 *
 * @export
 * @param {string} u (description)
 * @param {string} p (description)
 * @param {string} clientid (description)
 * @param {string} cburl (description)
 * @returns (description)
 */
export function authRequestAC( u: string, p: string, clientid: string, cburl: string, state: string = undefined  ) : Q.Promise<OAuthACResponse> {
  return postRequest<OAuthACResponse>(`/auth/authorize`, {
      grant_type: 'password',
      username: u,
      password: p,
      response_type: 'code',                // We are requesting a 'authorization code' to be used to get access tokens
      client_id: clientid,
      redirect_uri:cburl,
      state: state
  });

}

/**
 * Post a request for "Access Token" given a valid "Authorization Code".
 * Note: this request may be submitted as anonymous since it may be coming from an
 * authorized clientId
 * See FC 6749 at 4.1.3
 * http://tools.ietf.org/html/rfc6749#section-4.1.3
 *
 * @export
 * @param {string} ac Authorization Code
 * @param {string} clientid Client Application name
 * @param {string} cburl (optional) Callback URL
 * @param {string} state (optional) Client state
 * @returns (description)
 */
export function authRequestAT( ac: string, clientid: string, cburl: string = undefined, state: string = undefined ): Q.Promise<OAuthATResponse> {
  return postRequest<OAuthATResponse>(`/auth/token`, {
    grant_type: 'authorization_code',
    code: ac,
    client_id: clientid,
    redirect_uri: cburl,
    state: state
  });
}

export function authRequestNewAT( rt: string ) : Q.Promise<OAuthATResponse> {
  return postRequest<OAuthATResponse>('/auth/token', {
    grant_type: 'refresh_token',
    refresh_token: rt
  });
}

export function getImageData( level: number, face: string, rootPath: string ) : Q.Promise<HTMLImageElement> {
  const later = Q.defer<HTMLImageElement>();
  const xhr = new XMLHttpRequest();
  xhr.open('GET', `${rootPath}${level}/${face}`);
  xhr.responseType = 'arraybuffer';

  xhr.onload = function( e ) {
    const arrayBufferView = new Uint8Array( ( this as any ).response );
    const blob = new Blob( [ arrayBufferView ], { type: 'image/jpeg' } );
    const image = document.createElement( 'img' ) as HTMLImageElement;
    const url = window.URL || (window as any).webkitURL;
    image.src = url.createObjectURL(blob);
    later.resolve(image);
  };
  xhr.send();
  return later.promise;
  // return getRequest<Uint8Array>(`${rootPath}${level}/${face}`,'image/jpeg');
}


/**
 * Delete a file from a Location/Site
 *
 * @export
 * @param {string} siteid
 * @param {tvBase.SiteFile} filedesc
 * @returns {Q.Promise<>}
 */
export function deleteFile( siteid: string, fd: tvBase.SiteFile ) : Q.Promise<void> {
  return deleteRequest<void>(`/site/data/${siteid}?fname=${encodeURI(fd.name)}`);
}

export function deleteFiles( siteid: string, files: string[] ) : Q.Promise<void> {
  return deleteRequest<void>(`/site/data/${siteid}`, files );
}

/**
 * Rename a file from Location/Site
 *
 * @export
 * @param {string} siteid
 * @param {tvBase.SiteFile} filedes
 * @param {string} newname
 * @returns {Q.Promise< void >}
 */
export function setFileDisplayName( siteid: string, filedes: tvBase.SiteFile, newname: string ) : Q.Promise< void > {
  let later = Q.defer<void>();
  let modifyfile = _.cloneDeep( filedes );
  modifyfile.displayname = newname;
  delete modifyfile.index;
  patchRequest(`/site/meta/files/${siteid}`, { file: filedes, newfile: modifyfile } )
  .then( d => {
    later.resolve();
  })
  .fail( e => {
    later.reject( new Error( e.message ) );
  });
  return later.promise;
}

/**
 * admin users use this to test the ldap connection and search before saving
 * @export
 * @param {*} configs
 * @returns {Q.Promise<any>}
 */
export function testLdap( configs:any ) : Q.Promise<any>{
  let url = `/admin/ldap/test/`;
  return postRequest(url, configs);
}



export interface saveLdapConfigs {
  url?: string;
  baseDN?: string;
  username?: string;
  password?: string;
  filterstring?: string;
  type: string; // either 'password' or 'all'
}

/**
 * admin users save or change the ldap settings to the databse
 * @export
 * @param {*} configs
 * @returns {Q.Promise<any>}
 */
export function saveLdap( configs:saveLdapConfigs ): Q.Promise<any> {
  let url = '/admin/ldap/';
  return postRequest(url, configs);
}


export interface ldapDBEntry {
  url: string;
  baseDN: string;
  username: string;
  filterstring: string;
  type: string;
  groupselector: string;
}

/**
 * gets the ldap settings from database
 * @export
 * @returns {Q.Promise<{result: ldapDBEntry}>}
 */
export function getLdap(): Q.Promise<{result: ldapDBEntry}> {
  return getRequest<{result: ldapDBEntry}>('/admin/ldap/');
}

/**
 * Retrieve the users from a conneced LDAP/AD service
 *
 * @export
 * @param {number} [start=0]
 * @param {number} [count=10]
 * @returns {Q.Promise<Object>}
 */
export function getLdapUsers(query: string, start: number = 0, count: number = 10): Q.Promise<Object> {
  let url = `/admin/ldap/users?start=${start}&count=${count}`;
  if ( query.length > 0 ) {
    url += `&q=${query}`;
  }
  return getRequest<Object>(url)
  .then((data) => {
    return {
      users: data['users'],
      start: start,
      count: count,
      query: query
    };
  });
}

  /**
   * Type guard function for License
   * @export
   * @param {(ServiceLicense | License)} license
   * @returns {license is ServiceLicense}
   */
  export function isService( license: ServiceLicense | License ) : license is ServiceLicense {
    return license.type === 'cloud';
  }


/**
 * Convert a color from RGB to Hex code
 *
 * @internal
 * @export
 * @param {Color} color 
 * @returns {string} 
 */
export function rgbaToHex( color: Color ) : string {
  let convert = tc({ r: color.red, g: color.green, b: color.blue });
  return '#' + convert.toHex();
}

/**
 * Convert a Hex code into a color
 *
 * @internal
 * @export
 * @param {string} color 
 * @returns Color
 */
export function hexToRgba( color: string ) : Color { // color: string hex color #AA05CF
  let convert = tc( color );
  let c = convert.toRgb();
  return { red: c.r, green: c.g, blue: c.b, alpha: c.a };
}



/**
 * Calculate HSB equivalent color of RGB color
 *  references:
 *  http://www.rapidtables.com/convert/color/rgb-to-hsv.htm
 *  https://www.codeproject.com/Articles/19045/Manipulating-colors-in-NET-Part-1
 *
 * @internal
 * @export
 * @param {Color} col 
 * @returns {HSBColor} 
 */
export function rgbToHsb( col: Color ): HSBColor {
  let red = col.red / 255;
  let green = col.green / 255;
  let blue = col.blue / 255;
  let max = Math.max( red, green, blue );
  let min = Math.min( red, green, blue );
  let diff = max - min;

  let hue;
  if( diff === 0 ) {
    hue = 0;
  }
  else if( max === red && green >= blue ) {
    hue = 60 * ( green - blue ) / diff;
  }
  else if( max === red && green < blue ) {
    hue = 60 * ( green - blue ) / diff + 360;
  }
  else if( max === green ) {
    hue = 60 * ( blue - red ) / diff + 120;
  }
  else if( max === blue ) {
    hue = 60 * ( red - green ) / diff + 240;
  }

  let sat = (max === 0) ? 0 : 1 - min / max;
  let val = max;

  return { hue: hue, sat: sat, val: val };
}

/**
 *  Calculate RGB equivalent color of HSB color
 *  references:
 *  http://www.rapidtables.com/convert/color/rgb-to-hsv.htm
 *  https://www.codeproject.com/Articles/19045/Manipulating-colors-in-NET-Part-1
 *
 * @internal
 * @export
 * @param {HSBColor} col 
 * @returns {Color} 
 */
export function hsbToRgb( col: HSBColor ): Color {
  let c = col.val * col.sat;
  let x =  c * ( 1 - Math.abs( (col.hue / 60) % 2 - 1 ) );
  let m = col.val - c;

  let result;
  if ( col.hue >= 0 && col.hue < 60 ) {
    result = { red: (c + m ) * 255, green: (x + m ) * 255, blue: (0 + m ) * 255 };
  }
  else if ( col.hue >= 60 && col.hue < 120 ) {
    result = { red: (x + m ) * 255, green: (c + m ) * 255, blue: (0 + m ) * 255 };
  }
  else if ( col.hue >= 120 && col.hue < 180 ) {
    result = { red: (0 + m ) * 255, green: (c + m ) * 255, blue: (x + m ) * 255 };
  }
  else if ( col.hue >= 180 && col.hue < 240 ) {
    result = { red: (0 + m ) * 255, green: (x + m ) * 255, blue: (c + m ) * 255 };
  }
  else if ( col.hue >= 240 && col.hue < 300 ) {
    result = { red: (x + m ) * 255, green: (0 + m ) * 255, blue: (c + m ) * 255 };
  }
  else if ( col.hue >= 300 && col.hue < 360 ) {
    result = { red: (c + m ) * 255, green: (0 + m ) * 255, blue: (x + m ) * 255 };
  }

  result.red = Math.round( result.red );
  result.green = Math.round( result.green );
  result.blue = Math.round( result.blue);
  result.alpha = 1;

  return result;
}


export function getUsersDataPrivacy( id: string ): Q.Promise< any > {
  let url = `/privacy/${id}`;
  return getRequest< any >( url );
}
